import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { makeStyles, Button, Grid } from '@material-ui/core';
// import color from '../../color/theme';

const MessageDialog = (props) => {
    const {
        body,
        isOpen,
        deviceId,
        onClose,
        onOK,
    } = props;
    // const [playing, toggle] = useAudio('https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3');

    const modalStyle = {
        position: 'fixed',
        top: `calc(50%)`, // 'calc(95% - 100px)',
        left: 'calc(50%)',
        transform: 'translate(-50%, -50%)',
        zIndex: 10000,
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 35px 15px',
    };

    const centeredTextStyle = {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap', /* 允许文本内容自动换行 */
        width: '100%', /* 设置容器的宽度，根据需要调整 */
        backgroundColor: '#F4FBFC',
        color: '#555555',
        fontFamily: 'Microsoft JhengHei',
        fontSize: 16,
        fontWeight: 'bold'
    };


    const handleOK = () => {
        if (onOK) onOK(deviceId);
    };

    const handleClose = () => {
        if (onClose) onClose(deviceId);
    };
  
    if (!isOpen) return null;

    useEffect(() => {
        setTimeout(() => {
            onClose(deviceId);
        }, 1000 * 30);
    }, [isOpen]);

    const navStyle = {
        textAlign: 'center',
        lineHeight: '64px',
        fontSize: '21px',
        color: '#fff',
        backgroundColor: '#006E94',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 35px 15px',
        height: 60,
    };

    const bodyStyle = {
        backgroundColor: '#F4FBFC',
        height: 100,
        textAlign: 'center',
    };

    const footerStyle = {
        textAlign: 'center',
        height: 50,
        // lineHeight: '80px',
        border: '1px solid #EEEEEE',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        width: '100%',
        backgroundColor: '#F4FBFC'
    };

    return createPortal(
        <div style={modalStyle}>
            <div style={{ width: 320, height: 210 }}>
                <div style={navStyle}>
                    <Grid container
                      spacing={2} 
                      style={{ margin: 0 }} 
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                        語音通話
                    </Grid>
                </div>
                <div style={bodyStyle}>
                    <Grid container
                      spacing={2} 
                      style={{ margin: 0, width: '100%' }} 
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                        <Grid item xs={12} style={{marginTop: 10}}>
                            <div style={centeredTextStyle}>
                                {body}
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div style={footerStyle}>
                    <Grid container
                     spacing={2} 
                     style={{ margin: 0 }} 
                     direction="column"
                     alignItems="center"
                     justifyContent="center"
                    >
                        <Grid item xs={12}>
                            <Button onClick={handleClose} style={{ color: '#006E94', backgroundColor: 'white', border:'1px solid rgba(0, 0, 0, 0.23)', borderRadius: '4px', width: 84, height: 33, fontFamily: 'Microsoft JhengHei', fontSize: 16, fontWeight: 'bold'}}>
                                取消
                            </Button>
                            <Button onClick={handleOK} style={{ marginLeft: 10, color: 'white', backgroundColor: '#006E94', borderRadius: '4px', width: 84, height: 33, fontFamily: 'Microsoft JhengHei', fontSize: 16, fontWeight: 'bold'}}>
                                確定
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default MessageDialog;