import React, { useState, useEffect } from 'react';
import mqtt, { MqttClient } from 'mqtt';
import { AppConfig } from '../src/static/config/config';

const { MQTT_URL, MQTT_USERNAME, MQTT_PASSWORD } = AppConfig;
// const clientId = `mqttjs_browser_${Math.random().toString(16).substr(2, 8)}`;

export default function useMqtt({ token }) {
    const [client, setClient] = useState(null);
    const [payload, setPayload] = useState({
        topic: '',
        message: {},
    });
    const [status, setStatus] = useState(null);

    const createClient = (token) => {
        const client = mqtt.connect(MQTT_URL, {
            // clientId,
            protocolVersion: 5,
            // eslint-disable-next-line no-bitwise
            username: MQTT_USERNAME,
            password: MQTT_PASSWORD,
            properties: {
                sessionExpiryInterval: 4294967295,
            },
        });

        client.on('connect', () => {
            setStatus('Connected');
            console.log('Connected');
        });
        client.on('error', (err) => {
            console.error('Connection error: ', err);
            setStatus('Error');
            client.end();
        });
        client.on('reconnect', () => {
            console.log('Reconnecting');
        });
        client.on('message', (topic, msg) => {
            try {
                if (topic.startsWith("$SYS/") || topic.startsWith('device')) return;
                const message = JSON.parse(msg.toString());
                setPayload({ topic, message });
            } catch (error) {
                console.log(error);
            }
        });
        window.addEventListener('beforeunload', (ev) => {
            ev.preventDefault();
            client.end();
        });

        setClient(client);
    };

    const disconnect = () => {
        if (client) {
            client.end(false, () => {
                console.log('Disconnected from the MQTT broker');
            });
        }
    };

    useEffect(() => {
        if (token) {
            createClient(token);
        }
    }, [token]);

    return {
        client,
        payload,
        disconnect,
    };
}
