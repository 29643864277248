import React, { useState, useEffect } from 'react';
import { login, createConnection, getConnections } from './api/deviceManagementApi'; //'../main/api/deviceManagementApi';
import { apiUpdateByDeviceId } from './api/notificationApi';
import useMqtt from './hooks/useMqtt';
// import { jwtDecode } from "jwt-decode";
import VideoCallPopover from './component/CustomPopover/VideoCallPopover';
import IncomingCallPopover from './component/CustomPopover/IncomingCallPopover';
import SnackbarMqttNotif from './component/CustomPopover/SnackbarMqttNotif';
import MessageDialog from './component/CustomPopover/MessageDialog';
import { v4 as uuidV4 } from 'uuid';
import moment from 'moment';
// import { set } from 'lodash';
// import { message } from 'vfile-message';
// import {useSessionStorage} from 'react-use';

const Main = (props) => {
  const { notification, deviceId, setDeviceId, deviceName, videoPage, setVideoPage, organization } = props;
  const [token, setToken] = useState(null);
  const { client, payload, disconnect } = useMqtt({ token });
  const [callList, setCallList] = useState([]); // [{ deviceId: 'test001', body: '自主移動機器人3號' }
  const [videoCall, setVideoCall] = useState(null); // { deviceId: 'test001', body: '自主移動機器人3號' }

  const role = 'PUBLISHER';
  const [fullscreen, setFullscreen] = useState(false);
  const [connection, setConnection] = useState();
  const [isConnect, setIsConnect] = useState(false);
  const [devices, setDevices] = useState([]);
  const [roomName, setRoomName] = useState(null);
  const [message, setMessage] = useState('');
  const [isTalking, setIsTalking] = useState(false);
  const [robotId, setRobotId] = useState(null); // 紀錄 deviceId
  const isInspectionPage = videoPage === 'Inspection'; // 判斷是否是巡檢管理頁面

  const startLogin = (tokenWicentral) => {
    if (tokenWicentral) {
      setToken(tokenWicentral);
    }
  };

  const createVideoConnection = async (token, deviceId, roomName) => {
    try {
      setRoomName(roomName);
      console.log('createVideoConnection', deviceId, roomName);
      const wss_token = await createConnection({
        deviceId: deviceId.toString(),
        type: 'WEBRTC',
        roomName: roomName,
        // accountId: userId,
        role,
        isDevice: false,
        // name: 10,
      }, token).catch((error) => {
        console.log('createVideoConnection failed:', error);
        handleCloseVideoModal();
      });
      setConnection({ token: wss_token });
    } finally {
    }
  };

  const getVideoConnections = async (token, sessionId) => {
    const { data } = await getConnections(sessionId, token);
    return data;
  };

  const subscribe = () => {
    client?.subscribe(`amr/request/${organization}/call`);
    client?.subscribe(`amr/response/${organization}/#`); // 巡檢管理頁面使用
  };

  const onMessage = (payload) => {
    const { topic, message } = payload;
    const token = sessionStorage.getItem('tokenWicentral');
    if (topic && token) {
      if (message) {
        const action = message?.request?.action;
        console.log('action', action);
        console.log('message', message, message?.request?.body.roomName);
        try {
          if (action === 'startSession') {
            // filter 要判斷 Kiosk 要用 asm_info.id
            const device = devices.find(
              (item) => {
                if (item.asm_info?.type === 'Kiosk') {
                  return item.asm_info?.id.toString() === message.deviceId.toString();
                } else {
                  return item.id.toString() === message.deviceId.toString();
                }
              }
            );
            const newCallList = [
              ...callList,
              { deviceId: message.deviceId, body: device.name, roomNameM: message?.request?.body.roomName },
            ];
            if (newCallList.length > 3) {
              newCallList.shift();
            }
            setCallList(newCallList);
          } else if (action === 'acknowledgeCall') {
            if (!isTalking) {
              console.log('acknowledgeCall', '已被接通，自動掛斷');
              const newCallList = callList.filter((call) => call.deviceId !== message.deviceId);
              setCallList(newCallList);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    if (!token) {
      disconnect();
    }
  };

  const toggleFullScreen = () => {
    setFullscreen(true);
  };

  const onOK = async ({ deviceId, body, roomNameM }) => {
    try {
      console.log('onOK', deviceId, body, roomNameM);
      // 接通前先確認連線數
      const sessionId = "sessionAMR";
      const connections = await getVideoConnections(token, sessionId);
      const connectionsData = connections.content;
      console.log("connectionsData", connectionsData.length, connectionsData);

      let sessionCount = 0;
      connectionsData.forEach(connection => {
        const { roomName } = JSON.parse(connection.serverData); // 解析 serverData 字符串
        if (connection.status === "active" && roomNameM === roomName) {
          setRoomName(roomName);
          sessionCount++;
        }
        // setRoomName(roomName);
        // if (isNaN(roomName)) {
        //   if (roomName === deviceId) {
        //     sessionCount++;
        //   }
        // }
      });
      console.log("sessionCount", sessionCount);

      if (sessionCount === 0) { // 撥打connection數為0
        alert("無符合roomName!");
        handleCloseModal(deviceId);
        setVideoCall({ deviceId, body });
      } else if (sessionCount === 1 || !isNaN(roomNameM)) { // 僅撥打一個connection或是roomName=uuid不判斷
        createVideoConnection(token, deviceId, roomNameM);
        apiUpdateByDeviceId({ deviceId: deviceId.toString() });
        setIsTalking(true);
        handleSendMessage(deviceId);
        setTimeout(() => {
          handleCloseModal(deviceId);
          setVideoCall({ deviceId, body, roomNameM });
        }, 300);
      } else {
        alert("此通話已被接聽!");
        handleCloseModal(deviceId);
        setVideoCall({ deviceId, body });
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleSendMessage = (deviceId, roomName) => {
    const topic = `amr/request/${organization}/call`;
    const message = {
      deviceId,
      request: {
        // msgId: tenantId,
        action: "acknowledgeCall",
        level: "High",
        body: {
          msg: "HELPLINE CALL ACKNOWLEDGED",
          roomName,
          // userName:
          // userId:
        },
      },
      timestamp: moment().valueOf(),
    };
    client.publish(topic, JSON.stringify(message), { qos: 2 }, (err) => {
      if (err) {
        console.log(err?.message);
      }
    });
  };

  const publishToDevice = (roomName) => {
    const topic = `amr/command/${deviceId}`;
    const mode = 'VideoConference';
    const message = {
      deviceId,
      request: {
        // msgId: tenantId,
        action: 'startSession',
        level: 'High',
        body: {
          sessionID: connection?.sessionId,
          mode,
          roomName,
        },
      },
      timestamp: moment().valueOf(),
    };
    client.publish(topic, JSON.stringify(message), { qos: 2 }, (err) => {
      if (err) {
        console.log(err?.message);
      }
    });
  };

  const handleCloseModal = (deviceId) => {
    const newCallList = callList.filter(
      (item) => item.deviceId.toString() !== deviceId.toString()
    );
    setCallList(newCallList);
    setIsTalking(false);
  };

  const handleCloseVideoModal = () => {
    setConnection(null);
    setVideoCall(null);
    setIsTalking(false);
  };

  useEffect(() => {
    if (!videoCall && isInspectionPage) {
      setTimeout(() => {
        stopDeviceLiveStream();
      }, 2000); // 關閉 2 秒後發送訊息
    }
  }, [videoCall]);

  const stopDeviceLiveStream = () => {
    console.log('stopDeviceLiveStream', robotId);
    setVideoPage(null); // 重製頁面
    const topic = `amr/command/${organization}/${robotId}`;
    const payload = {
      deviceId: robotId,
      request: {
        msgId: 'test',
        action: 'stopLiveStream',
        level: 'High',
        body: {
          // status: 'ok',
        },
      },
      timestamp: new Date().getTime(),
    };

    client.publish(topic, JSON.stringify(payload), { qos: 2 }, (err) => {
      if (err) {
        console.log(err?.message);
      }
    });
  }

  // Active Video Call
  const startVideoCall = async (deviceId) => {
    // publish mqtt message to device
    let roomName = uuidV4();
    if (isInspectionPage) roomName = deviceId; // 巡檢管理頁面使用
    publishToDevice(roomName);
    // setRoomName(roomName);
    // create video connection
    await createVideoConnection(token, deviceId, roomName);
    // update notification by deviceId
    apiUpdateByDeviceId({ deviceId: deviceId.toString() });
    setTimeout(() => {
      try {
        // const deviceName = devices.find((item) => item.id.toString() === deviceId.toString())?.name;
        setVideoCall({ deviceId, body: deviceName });
      } catch (ex) {
        console.log(ex);
      }
    }, 300);
  };

  const getDeviceName = () => {
    if (deviceId) {
      // const deviceName = devices.find((item) => item.id.toString() === deviceId.toString())?.name;
      return `請問確認要撥號給${deviceName}嗎`;
    }
    return '';
  }

  const dialogOnOK = (deviceId) => {
    setDeviceId(null);
    if (deviceId) {
      startVideoCall(deviceId);
    }
  }

  const dialogOnClose = (deviceId) => {
    setDeviceId(null);
  }

  useEffect(() => {
    onMessage(payload);
  }, [payload]);

  useEffect(() => {
    if (client) {
      console.log('mqtt organization ', organization);
      subscribe();
    }
  }, [client, organization]);

  useEffect(() => {
    const state = notification;
    if (!state) return;
    switch (state.user.page) {
      case 'Login':
        const tokenWicentral = sessionStorage.getItem('tokenWicentral');
        if (tokenWicentral) {
          startLogin(tokenWicentral);
        }
        if (state.user?.devices) {
          setDevices(state.user.devices);
          sessionStorage.setItem('devices', JSON.stringify(state.user.devices));
        }
        break;
      default:
        break;
    }
  }, [notification]);

  useEffect(() => {
    const tokenWicentral = sessionStorage.getItem('tokenWicentral');
    if (tokenWicentral) {
      startLogin(tokenWicentral);
    }
    const devices = sessionStorage.getItem('devices');
    if (devices) {
      setDevices(JSON.parse(devices));
    }
  }, []);

  useEffect(() => {
    // 判斷是否是巡檢管理頁面，巡檢管理頁面，直接開啟視訊通話
    if (isInspectionPage && deviceId) {
      setRobotId(deviceId);

      dialogOnOK(deviceId);
    }
  }, [videoPage, deviceId]);

  return (
    <div>
      {
        callList &&
        callList.map((item, index) => {
          return (
            <IncomingCallPopover
              key={index}
              body={item.body}
              deviceId={item.deviceId}
              roomNameM={item.roomNameM}
              isOpen={true}
              onClose={handleCloseModal}
              onOK={onOK}
              index={index}
            />
          )
        })
      }
      {!isInspectionPage && (
        <MessageDialog
          body={getDeviceName()}
          deviceId={deviceId}
          isOpen={deviceId != null && deviceId != ''}
          onClose={dialogOnClose}
          onOK={dialogOnOK}
        />
      )}
      <VideoCallPopover
        title={videoCall?.body}
        // isOpen={true}
        isOpen={videoCall !== null && connection?.token}
        handleCloseModal={handleCloseVideoModal}
        connection={connection}
        deviceId={videoCall?.deviceId.toString()}
        roomName={roomName}
        fullscreen={fullscreen}
        setFullscreen={setFullscreen}
        role={role}
        toggleFullScreen={toggleFullScreen}
        videoPage={videoPage}
      />
      <SnackbarMqttNotif
        token={token}
      />
    </div>
  );
};

export default Main;
