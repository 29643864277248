import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import { subscribe, unSubscribe } from '@/components/Mqtt/Mqtt.jsx';
// import { getRobotSImg } from '@/page/Robots/RobotsUtils.js';
import NotificationSnackbar from './NotificationSnackbar.jsx';
import useMqtt from '../../hooks/useMqtt.js';
import amrPng from '../../src/static/icon/img_robot-mm.png';
import uvcPng from '../../src/static/icon/img_robot-uvc.png';
import deliverySlPng from '../../src/static/icon/img_robot-delivery-sl.png';
import deliveryMlPng from '../../src/static/icon/img_robot-delivery-ml.png';
import kioskPng from '../../src/static/icon/img_robot-kiosk.png';
import infoIcon from '../../src/static/icon/ic_info.png';


const SnackbarMqttNotif = (token) => {
  // const { t } = useTranslation();
  // const dispatch = useDispatch();
  const userInfoJSON = sessionStorage.getItem('userInfo');
  const userInfo = userInfoJSON ? JSON.parse(userInfoJSON) : null;
  const organization = userInfo ? userInfo.organization : null;
  const [index, setIndex] = useState(0);
  const storedSiteIdOptions = sessionStorage.getItem('siteIdOptions');
  const siteOptions = storedSiteIdOptions === null ? 'EMPTY' : storedSiteIdOptions.replace(/^"(.*)"$/, '$1');
  const { client, payload, disconnect } = useMqtt({ token });
  const [notification, setNotification] = useState([]);
  // console.log('organization', organization);
  const subscribe = () => {
    client?.subscribe("notification/" + organization);
  };

  const onMessage = (payload) => {
    const { topic, message } = payload;
    const token = sessionStorage.getItem('tokenWicentral');
    if (topic.startsWith("notification") && token) {
      if (message) {
        try {
          const updateTime = new Date().getTime();
          setNotification({
            ...message,
            updateTime: updateTime
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
    // if (!token) {
    //     disconnect();
    // }
  }

  useEffect(() => {
    if (client) {
      subscribe();
    }
  }, [client]);

  useEffect(() => {
    onMessage(payload);
  }, [payload]);

  useEffect(() => {
    setIndex(1);
  }, [notification]);

  const eventName = (event) => {
    // if (event === 'ORDER') return t('SERVICE_ORDER_TYPE');
    // if (event === 'STATUS') return t('STATUS');
    // if (event === 'TASK') return t('ROBOT_JOURNAL_TASK');
    // if (event === 'APP') return 'APP';
    // if (event === 'SITE') return 'SITE';
    if (event === 'ORDER') return '訂單';
    if (event === 'STATUS') return '狀態'
    if (event === 'TASK') return '任務';
    if (event === 'APP') return 'APP';
    if (event === 'SITE') return 'SITE';
    return '';
  };

  const eventType = (event_type) => {
    // if (event_type && event_type.toLowerCase() === 'error') return t('ERROR');
    if (event_type && event_type.toLowerCase() === 'error') return '異常';
    return '正常';
  };

  const getRobotImg = (asm_type, model_name) => {
    if (!asm_type || asm_type.trim() === '') {
      return infoIcon;
    }

    switch (asm_type.toLowerCase()) {
      case 'amr':
        return amrPng;
      case 'uvc':
        return uvcPng;
      case 'kiosk':
        return kioskPng;
      case 'adr':
        if (!model_name || model_name.trim() === '') {
          return deliverySlPng;
        }
        const model = model_name.slice(0, 2);
        if (model === 'SL') {
          return deliverySlPng;
        }
        return deliveryMlPng;
      default:
        return infoIcon;
    }
  };

  return (
    notification && (
      <NotificationSnackbar
        key={notification.created_time + notification.device}
        notification={{
          id: notification.id,
          message: siteOptions === 'EMPTY'
            ? `${notification.site_name ? notification.site_name : ''} - ${notification.device_name || ''}`
            : `${notification.device_name || ''}`,
          type: 'info',
          data1: ` ${eventName(notification.event)}`,
          data2: ` ${eventType(notification.event_type)}`,
          data3: ` ${notification.code}`,
          event_type: notification.event_type,
          vertical: 'bottom',
          horizontal: 'right',
          img: getRobotImg(notification.asm_type, notification.model_name),
          asm_type: notification.asm_type,
        }}
        id={notification.id}
      // index={i}
      // t={t}
      />
    )
  );
};

export default SnackbarMqttNotif;
