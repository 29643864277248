import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { makeStyles, Button, Grid } from '@material-ui/core';
import VideoRoomComponent from '../../component/VideoRoomComponent/VideoRoomComponent';
import color from '../../color/theme';
import FullScreen from '../../img/ic_pipExit.svg';
import { AppConfig } from '../../src/static/config/config';

const { OPENVIDU_SERVER_URL } = AppConfig;

// const onlyMessageModalStyle = makeStyles(() => ({
//     nav: {
//         textAlign: 'center',
//         height: '64px',
//         lineHeight: '64px',
//         fontSize: '21px',
//         color: '#fff',
//         backgroundColor: '#006E94',
//         borderTopRightRadius: '10px',
//         borderTopLeftRadius: '10px',
//         boxShadow: 'rgba(0, 0, 0, 0.35) 0px 35px 15px',
//     },
//     title: {
//         textAlign: 'center',
//         height: '64px',
//         lineHeight: '64px',
//         fontSize: '21px',
//         fontWeight: 'bold',
//         color: '#fff',
//         backgroundColor: color.blue1,
//         borderTopLeftRadius: '10px',
//         borderTopRightRadius: '10px',
//     },
//     footer: {
//         textAlign: 'center',
//         height: '80px',
//         lineHeight: '80px',
//         border: '1px solid #EEEEEE',
//         borderBottomLeftRadius: '10px',
//         borderBottomRightRadius: '10px',
//     },
// }));

const Timer = (props) => {
    const { isOpen } = props;
    let seconds = 0;
    let intervalId;
    const [result, setResult] = useState('00:00:00');

    const startTimer = () => {
        if (!intervalId) {
            intervalId = setInterval(updateTimer, 1000); // 每秒更新一次计时器
        }
    }

    const pauseTimer = () => {
        clearInterval(intervalId); // 暂停计时器
        intervalId = null;
    }

    const resetTimer = () => {
        seconds = 0;
        updateTimerDisplay();
        pauseTimer(); // 如果计时器正在运行，则先暂停
    }

    const updateTimer = () => {
        seconds++;
        updateTimerDisplay();
    }

    const updateTimerDisplay = () => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        setResult(`${padZero(hours)}:${padZero(minutes)}:${padZero(remainingSeconds)}`);
    }

    const padZero = (number) => {
        return number.toString().padStart(2, '0');
    }

    useEffect(() => {
        if (isOpen) startTimer();
    }, [isOpen]);

    return (
        <div>{result}</div>
    )
}

const VideoCallPopover = ({ title, isOpen, handleCloseModal, connection, deviceId, roomName, fullscreen, setFullscreen, role, toggleFullScreen, videoPage }) => {
    // const onlyMessageModalStyles = onlyMessageModalStyle();
    const modalStyle = {
        position: 'fixed',
        top: `calc(95% - 360px)`,
        left: 'calc(95% - 420px)',
        transform: 'translate(-50%, -50%)',
        zIndex: 10000,
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 35px 15px',
    };
    const [subscribers, setSubscribers] = useState([]);
    // const [mute, setMute] = useState(false);
    // const voiceHandler = () => {
    //     setMute(!mute);
    // };

    if (!isOpen) return null;

    const onUsersUpdate = (users) => {
        setSubscribers(users);
    };

    const generalStyle = {
        backgroundColor: '#494949',
        width: 420,
        height: 300,
        top: 'calc(95% - 50px)',
        left: '70%', position: 'absolute',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 35px 15px',
    };

    const inspectionStyle = {
        backgroundColor: '#494949',
        width: 1200,
        height: 800,
        top: 'calc(95% - 50px)',
        left: '70%', position: 'absolute',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
    };

    const inspectionModalStyle = {
        position: 'fixed',
        top: `calc(50% - 360px)`,
        left: 'calc(48% - 420px)',
        transform: 'translate(-50%, -50%)',
        zIndex: 10000,
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
    };

    return createPortal(
        <div style={videoPage === 'Inspection' ? inspectionModalStyle : modalStyle}>
            <div style={videoPage === 'Inspection' ? inspectionStyle : generalStyle}>
                {/* <div className={onlyMessageModalStyles.nav} style={{ height: 80 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={2} container alignItems="center">
                            <Button onClick={toggleFullScreen} style={{ zIndex: 1000, height: 50, width: 50, fontFamily: 'Microsoft JhengHei', fontSize: 21, fontWeight: 'bold', color: 'white' }}>
                                <FullScreen />
                            </Button>
                        </Grid>
                        <Grid item xs={10} style={{ marginTop: -18, transform: 'translateX(-39px)' }}>
                            <Grid item xs={12} style={{ height: 36, color: 'white', fontFamily: 'Microsoft JhengHei', fontSize: 21, fontWeight: 'bold' }}>
                                { title }
                            </Grid>
                            <Grid item xs={12} style={{ height: 36 }}>
                                { subscribers && (<Timer isOpen={subscribers.length > 0} />)}
                            </Grid>
                        </Grid>
                    </Grid>
                </div> */}
                {!!connection?.token && open && (
                    <VideoRoomComponent
                        openviduServerUrl={OPENVIDU_SERVER_URL}
                        token={connection?.token}
                        mode={'VideoConference'}
                        leaveSession={handleCloseModal}
                        // showInlineButton={mode !== ('Monitor' as OpenViduMode)}
                        id={deviceId}
                        sessionName={'sessionAMR'}
                        fullscreen={fullscreen}
                        setFullscreen={setFullscreen}
                        roomName={roomName}
                        roleName={role}
                        // mute={mute}
                        onUsersUpdate={onUsersUpdate}
                        title={title}
                        videoPage={videoPage}
                        start
                    />
                )}
                {/* <div>
                    <div className={onlyMessageModalStyles.footer} style={{ width: '100%', backgroundColor: '#F4FBFC' }}>
                        <Grid container style={{ height: '100%'}}>
                            <Grid item xs={2} container alignItems="center">
                                <Button onClick={voiceHandler} style={{ zIndex: 1000, transform: 'translateX(20px)', border: '1px solid #006E94', borderRadius: '4px', height: '43px', width: '43px'}}>
                                    { mute? (<VoiceOff />): (<VoiceOn />)}
                                </Button>
                            </Grid>
                            <Grid item xs={10} style={{ transform: 'translateX(-30px)'}} >
                                <Button onClick={handleCloseModal} style={{ color: 'white', backgroundColor: '#DB4242', borderRadius: '4px', width: 84, height: 43, fontFamily: 'Microsoft JhengHei', fontSize: 21, fontWeight: 'bold'}}>
                                    掛斷
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div> */}
            </div>
        </div>,
        document.body
    );
};

export default VideoCallPopover;