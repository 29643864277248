import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { initGlobalState } from 'qiankun' //'../../es';
import Main from '../main';

const { onGlobalStateChange, setGlobalState } = initGlobalState({
  user: 'qiankun',
});


/**
 * 渲染子应用
 */
function Render(props) {
  const { loading } = props;
  const [payload, setPayload] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [deviceName, setDeviceName] = useState(null);
  const [videoPage, setVideoPage] = useState(null);
  const [organization, setOrganization] = useState(JSON.parse(sessionStorage.getItem('userInfo'))?.organization);

  useEffect(() => {
    // 注册观察者函数
    // onGlobalStateChange 第二个参数为true,表示立即执行一次观察者函数
    onGlobalStateChange((state) => {
      if (state.ignore === 'master') return;
      console.log('[state]', state);
      // if (['Order','Script'].includes(state.user.page)) {
      //   history.pushState(null, null, "/order-management");
      // }
      switch (state.user.page) {
        case 'Dashboard':
          history.pushState(null, null, "/amr#/Dashboard");
          break;
        case 'Notification':
          history.pushState(null, null, "/amr#/Notification");
          break;
        case 'Maps':
          history.pushState(null, null, "/amr#/Maps");
          break;
        case 'Tasks':
          history.pushState(null, null, "/amr#/Tasks");
          break;
        case 'Account':
          history.pushState(null, null, "/amr#/Account");
          break;
        case 'Serial':
          history.pushState(null, null, "/amr#/Serial");
          break;
        case 'Organization':
          history.pushState(null, null, "/amr#/Organization");
          break;
        case 'Services':
          history.pushState(null, null, "/amr#/Services");
          break;
        case 'Order':
          history.pushState(null, null, "/wifundity/order-management");
          break;
        case 'Script':
          history.pushState(null, null, "/wifundity/script-management");
          break;
        case 'Inspection':
          history.pushState(null, null, "/amr#/Inspection");
          break;
        case 'FactoryTestMgmt':
          history.pushState(null, null, "/amr#/FactoryTestMgmt");
          break;
        case 'FactorySerial':
          history.pushState(null, null, "/amr#/FactorySerial");
          break;
        case 'FactoryTestItems':
          history.pushState(null, null, "/amr#/FactoryTestItems");
          break;
        case 'FactoryTestSetting':
          history.pushState(null, null, "/amr#/FactoryTestSetting");
          break;
        case 'FactoryTestReport':
          history.pushState(null, null, "/amr#/FactoryTestReport");
          break;
        case 'FactoryTest':
          history.pushState(null, null, "/amr#/FactoryTest");
          break;
        case 'Setting':
          history.pushState(null, null, "/amr#/Setting");
          break;
        case 'Personal':
          history.pushState(null, null, "/amr#/Personal");
          break;
        case 'Login':
          setPayload(state);
          break;
        case 'VideoCall':
          setDeviceId(state.user.deviceId);
          setDeviceName(state.user.deviceName)
          setVideoPage(state.user?.videoPage ?? null);

          // setTimeout(() => {
          //   setDeviceId(null);
          // }, 300);
          break;
        case 'Refresh':
          setOrganization(JSON.parse(sessionStorage.getItem('userInfo'))?.organization);
          break;
        case 'NoPermission':
          history.pushState(null, null, "/amr#/NoPermission");
        default:
          break;
      }
    }, true);

    window.addEventListener('popstate', function (event) {
      // 获取新的 URL 路径
      if (window.location.hash) { // hashRouter #portal-web
        var path = window.location.hash.replace('#/', '');
        sessionStorage.setItem('currentPage', path);
      } else {
        // BrowserRouter #orderlisy
        switch (window.location.pathname) {
          case 'wifundity/order-management':
            sessionStorage.setItem('currentPage', 'Order');
            break;
          case 'wifundity/script-management':
            sessionStorage.setItem('currentPage', 'Script');
            break;
        }
      }
    });
  }, []);

  //onGlobalStateChange((value, prev) => console.log('[onGlobalStateChange - master]:', value, prev));

  function handleClick(page) {
    setGlobalState({
      ignore: 'master',
      user: {
        name: 'master',
        page: page,
      },
      // user: {
      //   page: page,
      // }
    });
  }

  return (
    <>
      {loading && <h4 className="subapp-loading">Loading...</h4>}
      {/* <button onClick={e=>handleClick('/Maps')}>Maps</button>
      <button onClick={e=>handleClick('/Dashboard')}>Dashboard</button>
      <button onClick={e=>handleClick('/script-management')}>script-management</button>
      <button onClick={e=>handleClick('/order-management')}>order-management</button> */}
      <Main notification={payload} deviceId={deviceId} setDeviceId={setDeviceId} deviceName={deviceName} videoPage={videoPage} setVideoPage={setVideoPage} organization={organization} />
      <div id="subapp-viewport" />
      {/* <div id="subapp-viewport2" />
      <div id="subapp-viewport3" /> */}
    </>
  );
}

export default function render({ loading }) {
  const container = document.getElementById('subapp-container');
  ReactDOM.render(<Render loading={loading} />, container);
}
