import React, { useState, useEffect, useContext } from 'react';
import { Snackbar as MuiSnackbar, createTheme, ThemeProvider } from '@material-ui/core';
// import { Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const NotificationSnackbar = (props) => {
  const { notification } = props;
  const { id } = notification;
  const [open, setOpen] = useState(false);
  const [position] = useState({ vertical: 'bottom', horizontal: 'right' });
  const [snackbarList, setSnackbarList] = useState([]);



  const handleClose = (idToRemove) => {
    // console.log('close', idToRemove);
    const updatedSnackbarList = snackbarList.filter((snackbar) => snackbar.id !== idToRemove);
    setSnackbarList(updatedSnackbarList);
  };

  const handleNavigation = () => {
    const currentPage = sessionStorage.getItem('currentPage');
    const id = 'Notification';
    const name = 'NOTIFICATION';
    sessionStorage.setItem('currentPage', id);
    sessionStorage.setItem('currentPageName', name);
    // 使用 history.pushState 改變路由位置
    if (!['Order', 'Script'].includes(currentPage)) {
      // 同專案轉頁有問題，所以先導到別頁再轉回來
      history.pushState(null, null, "/wifundity/order-management");
      history.pushState(null, null, "/amr#/Notification");
    } else {
      history.pushState(null, null, "/amr#/Notification");
    }
  };



  // 新增id進來後就寫到snackbarList
  useEffect(() => {
    // console.log('get snackbar', notification);
    // 依序加入snackbarList
    setSnackbarList((prev) => [...prev, notification]);
    // 刪除mqtt的notification id一樣的
    // dispatch(clearNotificationMqttById(notification.updateTime));
    // const notificationToDelete = state.notification.filter((x) => x.updateTime !== updateTime);
    // state.notification = notificationToDelete;
  }, [id]);

  // 監聽snackbarList的變化，並且依序打開
  useEffect(() => {
    // console.log('snackbarList', snackbarList);
    if (snackbarList.length > 0) {
      setOpen(true);
    }
  }, [snackbarList]);

  const theme = createTheme({
    overrides: {
      MuiSnackbar: {
        root: {
          '& .MuiAlert-root': {
            backgroundColor: '#fff',
          },
        },
      },
    },
  });

  return (
    <>
      {snackbarList && snackbarList.map((snackbar, index) => (
        snackbar.id !== undefined && (
          <ThemeProvider theme={theme}>
            <MuiSnackbar
              key={snackbar.id}
              open={open}
              autoHideDuration={5000}
              onClose={() => handleClose(snackbar.id)}
              anchorOrigin={position}
              style={{
                zIndex: 9999,
                position: 'absolute',
                marginBottom: index * 75,
                cursor: 'pointer',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                borderLeft: '8px solid rgb(0, 110, 148)',
                borderRadius: '10px',
              }}
              onClick={handleNavigation}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Alert
                  severity={snackbar.type}
                  icon={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={snackbar.img}
                        alt="Info"
                        style={{
                          padding: '0',
                          margin: '0',
                          width: snackbar.asm_type === '' ? '25px' : '35px',
                          height: snackbar.asm_type === '' ? '25px' : '35px',
                        }}
                      />
                    </div>
                  }
                >
                  <div>
                    <strong style={{ textAlign: 'center' }}>{snackbar.message}</strong>
                    <div style={{ display: 'block' }}>
                      <span style={{ margin: 0 }}>{snackbar.data1}{' '}</span>
                      {snackbar.event_type && snackbar.event_type.toLowerCase() === 'error' ? (
                        <span style={{ color: 'red', marginLeft: 5 }}>{snackbar.data2}</span>
                      ) : (
                        <span style={{ marginLeft: 5 }}>{snackbar.data2}</span>
                      )}
                      <span style={{ marginLeft: 5, maxWidth: '400px', wordWrap: 'break-word', whiteSpace: 'normal' }}>{' '}{snackbar.data3}</span>
                    </div>
                  </div>

                </Alert>
                {/* {open && (
                  <Close
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.5 }}
                    onClick={() => handleClose(snackbar.id)}
                  />
                )} */}
              </div>
            </MuiSnackbar>
          </ThemeProvider>
        )
      ))}
    </>
  );
};

export default NotificationSnackbar;
