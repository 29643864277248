/* eslint-disable react/sort-comp */
/* eslint-disable prefer-const */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-shorthand */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-lonely-if */
/* eslint-disable default-case */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';
// import PropTypes from 'prop-types';
import moment from 'moment';
import './VideoRoomComponent.css';
import { OpenVidu } from 'openvidu-browser';
import StreamComponent from '../Stream/StreamComponent';
// import DialogExtensionComponent from 'component/DialogExtension/DialogExtension';
// import ChatComponent from 'component/Chat/ChatComponent';

import OpenViduLayout from '../openvidu-layout';
import UserModel from '../user-model';
import { Button, Grid } from '@material-ui/core';
import VoiceOff from '../../img/ic_voice_off.svg';
import VoiceOn from '../../img/ic_voice.svg';
import FullScreen from '../../img/ic_pipExit.svg';
import Close from '../../img/btn_close.svg';
import VolumeOff from '../../img/ic_volume_off_blue.svg';
import VolumeOn from '../../img/ic_volume_on_blue.svg';
import FullscreenOff from '../../img/ic_fullscreen_off_blue.svg';
import FullscreenOn from '../../img/ic_fullscreen_on.svg';


// import ToolbarComponent from 'component/Toolbar/ToolbarComponent';
// import FooterToolbarComponent from 'component/FooterToolbar/FooterToolbarComponent';
// import phone_default from 'assets/image/pic/phone_default.png';
// import { isJson, getMutiJsonString } from 'utils/webHelper';
// import {
//     icon_camera,
//     icon_phone,
//     icon_multi_cam_1,
//     icon_multi_cam_2,
//     icon_multi_cam_3,
//     icon_multi_cam_4,
//     icon_multi_cam_5,
// } from 'assets/image';
// import { DEVICE_TYPE } from 'api/mapApi';
// import MonitorStreamComponent from './MonitorStreamComponent';

const localUser = new UserModel();

class VideoRoomComponent extends Component {
    constructor(props) {
        super(props);
        this.OPENVIDU_SERVER_URL = this.props.openviduServerUrl
            ? this.props.openviduServerUrl
            : `https://${window.location.hostname}:4443`;
        this.OPENVIDU_SERVER_SECRET = this.props.openviduSecret
            ? this.props.openviduSecret
            : '1Qaz2Wsx';
        this.hasBeenUpdated = false;
        this.layout = new OpenViduLayout();
        const sessionName = this.props.sessionName
            ? this.props.sessionName
            : 'SessionA';
        const roomName = this.props.roomName ? this.props.roomName : 'Room-0';
        const role = this.props.roleName ? this.props.roleName : 'MODERATOR';
        const userName = this.props.user
            ? this.props.user
            : `OpenVidu_User${Math.floor(Math.random() * 100)}`;
        this.remotes = [];
        this.localUserAccessAllowed = false;
        this.state = {
            mySessionId: sessionName,
            myUserName: userName,
            session: undefined,
            localUser: undefined,
            subscribers: [],
            chatDisplay: 'none',
            sessionStartTime: undefined,
            speaker: { connectionId: '', accountName: '' },
            roomName,
            role,
            camNoList: [],
            fullscreen: false,
            isLeaveSession: false,
            mutedSound: false,
        };
        this.openMediaSet = this.props.openMediaSet
            ? this.props.openMediaSet
            : null;
        this.mute = this.props.mute ? this.props.mute : false;

        if (this.props.mode) {
            switch (this.props.mode) {
                case 'AudioConference':
                    localUser.setVideoActive(false);
                    localUser.setAudioActive(true);
                    break;
                case 'VideoConference':
                    localUser.setVideoActive(true);
                    localUser.setAudioActive(true);
                    break;
                case 'Monitor':
                    localUser.setVideoActive(false);
                    localUser.setAudioActive(false);
                    break;
            }
            localUser.setMode(this.props.mode);
        }

        this.joinSession = this.joinSession.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.onbeforeunload = this.onbeforeunload.bind(this);
        this.updateLayout = this.updateLayout.bind(this);
        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.micStatusChanged = this.micStatusChanged.bind(this);
        this.changeMute = this.changeMute.bind(this);
        this.nicknameChanged = this.nicknameChanged.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.screenShare = this.screenShare.bind(this);
        this.stopScreenShare = this.stopScreenShare.bind(this);
        this.closeDialogExtension = this.closeDialogExtension.bind(this);
        this.toggleChat = this.toggleChat.bind(this);
        this.checkNotification = this.checkNotification.bind(this);
        this.checkSize = this.checkSize.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.getDisplay = this.getDisplay.bind(this);
        this.onfullscreenchange = this.onfullscreenchange.bind(this);

        this.subscribeUnPublishAll = this.subscribeUnPublishAll.bind(this);
        this.checkPriority = this.checkPriority.bind(this);
        this.onClose = this.onClose.bind(this);
        // this.getSubscriberById = this.getSubscriberById.bind(this);
        // this.getIconByType = this.getIconByType.bind(this);
        // this.getArrayByTotal = this.getArrayByTotal.bind(this);
    }

    componentDidMount() {
        const openViduLayoutOptions = {
            maxRatio: 3 / 2, // The narrowest ratio that will be used (default 2x3)
            minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
            fixedRatio: false, // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
            bigClass: 'OV_big', // The class to add to elements that should be sized bigger
            bigPercentage: 0.8, // The maximum percentage of space the big ones should take up
            bigFixedRatio: false, // fixedRatio for the big ones
            bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
            bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
            bigFirst: true, // Whether to place the big one in the top left (true) or bottom right
            animate: true, // Whether you want to animate the transitions
        };

        this.layout.initLayoutContainer(
            document.getElementById(`layout-${this.props.id}`),
            openViduLayoutOptions
        );
        window.addEventListener('beforeunload', this.onbeforeunload);
        window.addEventListener('resize', this.updateLayout);
        window.addEventListener('resize', this.checkSize);
        if (document.getElementById(`container-${this.props.id}`)) {
            document.getElementById(
                `container-${this.props.id}`
            ).onfullscreenchange = this.onfullscreenchange;
            document.getElementById(
                `container-${this.props.id}`
            ).onmsfullscreenchange = this.onfullscreenchange;
            document.getElementById(
                `container-${this.props.id}`
            ).onmozfullscreenchange = this.onfullscreenchange;
            document.getElementById(
                `container-${this.props.id}`
            ).onwebkitfullscreenchange = this.onfullscreenchange;
        }

        if (this.props.start) {
            this.joinSession();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onbeforeunload);
        window.removeEventListener('resize', this.updateLayout);
        window.removeEventListener('resize', this.checkSize);
        this.leaveSession(); // 離開
    }

    componentDidUpdate(lastProps) {
        if (lastProps.fullscreen !== this.props.fullscreen) {
            this.toggleFullscreen(this.props.fullscreen);
        }
        if (lastProps.start !== this.props.start && this.props.start) {
            this.joinSession();
        }
        if (lastProps.mute !== this.props.mute) {
            // this.changeMute(this.props.mute);
            this.micStatusChanged();
        }
        if (lastProps.roomName != this.props.roomName) {
            this.setState({
                roomName: this.props.roomName,
            });
        }
    }

    onbeforeunload(event) {
        this.leaveSession();
    }

    onfullscreenchange = () => {
        const document = window.document;
        if (this.props.setFullscreen) {
            const fullScreenMode =
                document.fullscreenElement ||
                document.mozFullScreenElement ||
                document.webkitFullscreenElement ||
                document.msFullscreenElement;
            this.props.setFullscreen(!!fullScreenMode);
        }
    };

    // 加入會議
    joinSession() {
        this.OV = new OpenVidu();

        this.setState(
            {
                session: this.OV.initSession(),
                sessionStartTime: moment(),
            },
            () => {
                this.subscribeToStreamCreated();
                this.connectToSession();
                if (this.props.fullscreen) {
                    this.toggleFullscreen(true);
                }
            }
        );
    }

    connectToSession() {
        if (this.props.token !== undefined) {
            console.log('token received: ', this.props.token);
            this.connect(this.props.token);
        } else {
            this.getToken()
                .then((token) => {
                    console.log(token);
                    this.connect(token);
                })
                .catch((error) => {
                    if (this.props.error) {
                        this.props.error({
                            error: error.error,
                            messgae: error.message,
                            code: error.code,
                            status: error.status,
                        });
                    }
                    console.log(
                        'There was an error getting the token:',
                        error.code,
                        error.message
                    );
                    alert(
                        'There was an error getting the token:',
                        error.message
                    );
                });
        }
    }

    connect(token) {
        this.state.session
            .connect(token, { clientData: this.state.myUserName })
            .then(() => {
                let source = undefined;
                if (this.props.videoPage === 'Inspection') source = false; // 巡檢管理 > 即時影像不需要開啟鏡頭 & 麥克風
                this.connectWebCam(source);
            })
            .catch((error) => {
                if (this.props.error) {
                    this.props.error({
                        error: error.error,
                        messgae: error.message,
                        code: error.code,
                        status: error.status,
                    });
                }
                alert(
                    'There was an error connecting to the session:',
                    error.message
                );
                console.log(
                    'There was an error connecting to the session:',
                    error.code,
                    error.message
                );
            });
    }

    checkPriority(remotes) {
    }

    connectWebCam(source) {
        const publisher = this.OV.initPublisher(undefined, {
            audioSource: source,
            videoSource: source, // 巡檢管理 > 即時影像不需要開啟鏡頭
            publishAudio: localUser.isAudioActive(),
            publishVideo: localUser.isVideoActive(),
            resolution: '1280x720',
            frameRate: 30,
            insertMode: 'APPEND',
        });

        if (this.state.session.capabilities.publish) {
            publisher.on('accessAllowed', () => {
                this.state.session.publish(publisher).then(() => {
                    // this.checkPriority(this.remotes);
                    this.updateSubscribers();
                    this.localUserAccessAllowed = true;
                    if (this.props.joinSession) {
                        this.props.joinSession();
                    }
                });
            });
        }
        localUser.setNickname(this.state.myUserName);
        localUser.setConnectionId(this.state.session.connection.connectionId);
        localUser.setScreenShareActive(false);
        localUser.setStreamManager(publisher);
        this.subscribeToUserChanged();
        this.subscribeToStreamDestroyed();
        this.sendSignalUserChanged({
            isScreenShareActive: localUser.isScreenShareActive(),
        });

        this.setState({ localUser: localUser }, () => {
            this.state.localUser.getStreamManager().on('streamPlaying', (e) => {
                this.updateLayout();
                publisher.videos[0].video.parentElement.classList.remove(
                    'custom-class'
                );
            });
        });
    }

    applyFilter() {
        const publisher = localUser.getStreamManager();
        publisher.stream.applyFilter('FaceOverlayFilter').then((filter) => {
            filter.execMethod('setOverlayedImage', {
                uri: 'https://cdn.pixabay.com/photo/2013/07/12/14/14/derby-148046_960_720.png',
                offsetXPercent: '-0.2F',
                offsetYPercent: '-0.8F',
                widthPercent: '1.3F',
                heightPercent: '1.0F',
            });
        });
    }

    updateSubscribers() {
        const subscribers = this.remotes;
        this.setState(
            {
                subscribers: subscribers,
            },
            () => {
                if (this.state.localUser) {
                    this.sendSignalUserChanged({
                        isAudioActive: this.state.localUser.isAudioActive(),
                        isVideoActive: this.state.localUser.isVideoActive(),
                        nickname: this.state.localUser.getNickname(),
                        isScreenShareActive:
                            this.state.localUser.isScreenShareActive(),
                    });
                }
                this.updateLayout();
                if (this.props.onUsersUpdate) {
                    this.props.onUsersUpdate(subscribers);
                }
            }
        );
    }

    // 如沒有正常執行會造成卡頓
    leaveSession() {
        const mySession = this.state.session;

        if (mySession) {
            mySession.disconnect();
        }

        if (this.props.fullscreen) {
            this.toggleFullscreen();
        }

        // Empty all properties...
        this.OV = null;
        this.setState({
            session: undefined,
            subscribers: [],
            mySessionId: 'SessionA',
            myUserName: `OpenVidu_User${Math.floor(Math.random() * 100)}`,
            localUser: undefined,
            sessionStartTime: undefined,
        });
        if (this.props.leaveSession) {
            this.props.leaveSession();
        }
    }
    camStatusChanged() {
        localUser.setVideoActive(!localUser.isVideoActive());
        localUser.getStreamManager().publishVideo(localUser.isVideoActive());
        this.sendSignalUserChanged({
            isVideoActive: localUser.isVideoActive(),
        });
        this.setState({ localUser: localUser });
    }

    micStatusChanged() {
        localUser.setAudioActive(!localUser.isAudioActive());
        localUser.getStreamManager().publishAudio(localUser.isAudioActive());
        this.sendSignalUserChanged({
            isAudioActive: localUser.isAudioActive(),
        });
        this.setState({ localUser: localUser });
    }

    changeMute(isMute) {
        const isActive = !isMute;
        localUser.setAudioActive(isActive);
        localUser.getStreamManager().publishAudio(isActive);
        this.sendSignalUserChanged({
            isAudioActive: isActive,
        });
        this.setState({ localUser: localUser });
    }

    nicknameChanged(nickname) {
        const localUser = this.state.localUser;
        localUser.setNickname(nickname);
        this.setState({ localUser: localUser });
        this.sendSignalUserChanged({
            nickname: this.state.localUser.getNickname(),
        });
    }

    deleteSubscriber(stream) {
        const remoteUsers = this.state.subscribers;
        const userStream = remoteUsers.filter(
            (user) => user.getStreamManager().stream === stream
        )[0];
        const index = remoteUsers.indexOf(userStream, 0);
        if (index > -1) {
            remoteUsers.splice(index, 1);
            this.setState({
                subscribers: remoteUsers,
            });
            // 如果subscriber離開後沒有人在訂閱，就離開session
            if (this.state.subscribers.length === 0) {
                this.leaveSession();
            }
        }
    }

    subscribeToStreamCreated() {
        this.state.session.on('streamCreated', (event) => {
            let rawData = null;
            if (event.stream.connection.data) {
                try {
                    const dataLists = event.stream.connection.data.split('%/%');
                    const trimedData =
                        dataLists.length > 1 ? dataLists[1] : dataLists[0];
                    rawData = JSON.parse(trimedData);
                    this.setState({
                        camNoList: [
                            ...this.state.camNoList,
                            {
                                deviceId: rawData.deviceId,
                                camNo: rawData?.camNo,
                                total: rawData?.total,
                            },
                        ],
                    });
                } catch (e) {
                    console.error(e);
                }
            }
            if (rawData) {
                // 如果不在同個房間，不要訂閱
                if (
                    !this.props.groupList &&
                    this.state.roomName.toString() !== rawData.roomName.toString()
                ) {
                    console.log(`1 on 1 user is ${rawData.roomName}`);
                    return;
                }
                if (
                    this.props.groupList &&
                    this.props.groupList.filter(
                        (device) => device.deviceId === rawData.roomName
                    ).length === 0
                ) {
                    console.log(`monitor user is ${rawData.roomName}`);
                    return;
                }
            }
            const subscriber = this.state.session.subscribe(
                event.stream,
                undefined
            );
            // var subscribers = this.state.subscribers;
            subscriber.on('streamPlaying', (e) => {
                this.checkSomeoneShareScreen();
                subscriber.videos[0].video.parentElement.classList.remove(
                    'custom-class'
                );
            });
            const newUser = new UserModel();
            newUser.setStreamManager(subscriber);
            newUser.setConnectionId(event.stream.connection.connectionId);
            newUser.setType('remote');
            // const nickname = event.stream.connection.data.split('%')[0];
            // newUser.setNickname(JSON.parse(nickname).clientData);
            newUser.setNickname(this.state.myUserName);
            newUser.setMode(localUser.getMode());
            this.remotes.push(newUser);
            this.updateSubscribers();
        });

        // 訊息都是統一發送，看是誰接收而已
        // 接收該 session 的訊息
        this.state.session.on('signal', (event) => {
            if (event.type === 'signal:Speak') {
            }
        });
    }

    // 請其他人中斷Publish
    subscribeUnPublishAll() {
        let dtConnection = [];
        this.remotes.forEach((Stream) => {
            if (Stream) {
                dtConnection.push(Stream.connectionId);
            }
        });
        if (dtConnection.length > 0) {
            const signalOptions = {
                session: 'SessionT',
                to: dtConnection,
                type: 'Speak',
            };
            this.state.session.signal(signalOptions);
        }
    }

    subscribeToStreamDestroyed() {
        // On every Stream destroyed...
        this.state.session.on('streamDestroyed', (event) => {
            // Remove the stream from 'subscribers' array
            this.deleteSubscriber(event.stream);
            setTimeout(() => {
                this.checkSomeoneShareScreen();
            }, 20);
            event.preventDefault();
            this.updateLayout();
        });
    }

    // 我發送一個令牌到不同的 device ，各 device 帶著此令牌來加入
    subscribeToUserChanged() {
        this.state.session.on('signal:userChanged', (event) => {
            const remoteUsers = this.state.subscribers;
            remoteUsers.forEach((user) => {
                if (user.getConnectionId() === event.from.connectionId) {
                    const data = JSON.parse(event.data);
                    console.log('EVENTO REMOTE: ', event.data);
                    if (data.isAudioActive !== undefined) {
                        user.setAudioActive(data.isAudioActive);
                    }
                    if (data.isVideoActive !== undefined) {
                        user.setVideoActive(data.isVideoActive);
                    }
                    if (data.nickname !== undefined) {
                        user.setNickname(data.nickname);
                    }
                    if (data.isScreenShareActive !== undefined) {
                        user.setScreenShareActive(data.isScreenShareActive);
                    }
                }
            });
            this.setState(
                {
                    subscribers: remoteUsers,
                },
                () => this.checkSomeoneShareScreen()
            );
        });
    }

    updateLayout() {
        // setTimeout(() => {
        //     this.layout.updateLayout();
        // }, 20);
    }

    sendSignalUserChanged(data) {
        const signalOptions = {
            data: JSON.stringify(data),
            type: 'userChanged',
        };
        this.state.session.signal(signalOptions);
    }

    toggleFullscreen(isFullscreen) {
        try {
            const document = window.document;
            const fs = document.getElementById(`container-${this.props.id}`);
            const nextFullscreen =
                isFullscreen !== undefined && isFullscreen !== null
                    ? isFullscreen
                    : !this.props.fullscreen;
            if (nextFullscreen) {
                if (
                    !document.fullscreenElement &&
                    !document.mozFullScreenElement &&
                    !document.webkitFullscreenElement &&
                    !document.msFullscreenElement
                ) {
                    if (fs.requestFullscreen) {
                        fs.requestFullscreen();
                    } else if (fs.msRequestFullscreen) {
                        fs.msRequestFullscreen();
                    } else if (fs.mozRequestFullScreen) {
                        fs.mozRequestFullScreen();
                    } else if (fs.webkitRequestFullscreen) {
                        fs.webkitRequestFullscreen();
                    }
                }
            } else {
                if (
                    document.fullscreenElement ||
                    document.mozFullScreenElement ||
                    document.webkitFullscreenElement ||
                    document.msFullscreenElement
                ) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen();
                    }
                }
            }
            this.setState(
                {
                    fullscreen: nextFullscreen,
                },
            );
        } catch (e) {
            console.error(e);
        }
    }

    screenShare() {
        const videoSource =
            navigator.userAgent.indexOf('Firefox') !== -1 ? 'window' : 'screen';
        const publisher = this.OV.initPublisher(
            undefined,
            {
                videoSource: videoSource,
                publishAudio: localUser.isAudioActive(),
                publishVideo: localUser.isVideoActive(),
                mirror: false,
            },
            (error) => {
                if (error && error.name === 'SCREEN_EXTENSION_NOT_INSTALLED') {
                    this.setState({ showExtensionDialog: true });
                } else if (
                    error &&
                    error.name === 'SCREEN_SHARING_NOT_SUPPORTED'
                ) {
                    alert('Your browser does not support screen sharing');
                } else if (
                    error &&
                    error.name === 'SCREEN_EXTENSION_DISABLED'
                ) {
                    alert('You need to enable screen sharing extension');
                } else if (error && error.name === 'SCREEN_CAPTURE_DENIED') {
                    alert(
                        'You need to choose a window or application to share'
                    );
                }
            }
        );

        publisher.once('accessAllowed', () => {
            this.state.session.unpublish(localUser.getStreamManager());
            localUser.setStreamManager(publisher);
            this.state.session
                .publish(localUser.getStreamManager())
                .then(() => {
                    localUser.setScreenShareActive(true);
                    this.setState({ localUser: localUser }, () => {
                        this.sendSignalUserChanged({
                            isScreenShareActive:
                                localUser.isScreenShareActive(),
                        });
                    });
                });
        });
        publisher.on('streamPlaying', () => {
            this.updateLayout();
            publisher.videos[0].video.parentElement.classList.remove(
                'custom-class'
            );
        });
    }

    closeDialogExtension() {
        this.setState({ showExtensionDialog: false });
    }

    stopScreenShare() {
        this.state.session.unpublish(localUser.getStreamManager());
        this.connectWebCam();
    }

    checkSomeoneShareScreen() {
        let isScreenShared;
        // return true if at least one passes the test
        isScreenShared =
            this.state.subscribers.some((user) => user.isScreenShareActive()) ||
            localUser.isScreenShareActive();
        const openviduLayoutOptions = {
            maxRatio: 3 / 2,
            minRatio: 9 / 16,
            fixedRatio: isScreenShared,
            bigClass: 'OV_big',
            bigPercentage: 0.8,
            bigFixedRatio: false,
            bigMaxRatio: 3 / 2,
            bigMinRatio: 9 / 16,
            bigFirst: true,
            animate: true,
        };
        this.layout.setLayoutOptions(openviduLayoutOptions);
        this.updateLayout();
    }

    toggleChat(property) {
        let display = property;

        if (display === undefined) {
            display = this.state.chatDisplay === 'none' ? 'block' : 'none';
        }
        if (display === 'block') {
            this.setState({ chatDisplay: display, messageReceived: false });
        } else {
            console.log('chat', display);
            this.setState({ chatDisplay: display });
        }
        this.updateLayout();
    }

    checkNotification(event) {
        this.setState({
            messageReceived: this.state.chatDisplay === 'none',
        });
    }
    checkSize() {
        if (
            document.getElementById(`layout-${this.props.id}`).offsetWidth <=
            700 &&
            !this.hasBeenUpdated
        ) {
            this.toggleChat('none');
            this.hasBeenUpdated = true;
        }
        if (
            document.getElementById(`layout-${this.props.id}`).offsetWidth >
            700 &&
            this.hasBeenUpdated
        ) {
            this.hasBeenUpdated = false;
        }
    }

    getDisplay() {
        if (
            localUser !== undefined &&
            localUser.getMode() !== 'AudioConference'
        ) {
            return {};
        }
        return {
            display: 'none',
        };
    }

    getArrayByTotal(gDeviceId, camCount) {
        const camList = this.state.camNoList.filter(
            (element) => element.deviceId === gDeviceId
        );
        const total = camCount > 0 ? camCount : 1;
        const needAdd = total - camList.length;
        for (let i = 0; i < needAdd; i += 1) {
            camList.push({
                deviceId: gDeviceId,
                total: total,
            });
        }

        return camList;
    }

    onClose() {
        this.setState({
            isLeaveSession: true
        });
        setTimeout(() => {
            this.leaveSession();
        }, 1000);
    }

    handleSetMutedSound(value) {
        this.setState(
            {
                mutedSound: value,
            },
        );
    };

    render() {
        // const mySessionId = this.state.mySessionId;
        const localUser = this.state.localUser;
        const fullscreen = this.state.fullscreen;
        const role = this.state.role;
        const volumn = this.state.mutedSound;

        console.log('volumn', volumn);
        const footerStyle = {
            textAlign: 'center',
            height: '80px',
            lineHeight: '80px',
            border: '1px solid #EEEEEE',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            width: '100%',
            backgroundColor: '#F4FBFC',
            boxShadow: '0px 3px 6px #00000033',
        };

        const navStyle = {
            textAlign: 'center',
            height: '64px',
            lineHeight: '64px',
            fontSize: '21px',
            color: '#fff',
            backgroundColor: '#006E94',
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 35px 15px',
            height: 80,
        };

        const buttonStyle = {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'white',
            border: '1px solid #006E94',
        };

        return (
            <div className="container" id={`container-${this.props.id}`}>
                {/* 即時影像頁面 */}
                {this.props.videoPage === 'Inspection' ? (
                    <>
                        {!fullscreen && (
                            <div style={navStyle}>
                                <Grid container spacing={2} style={{ margin: 0 }}>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ flex: 1 }}>
                                            <p style={{ color: 'white', fontFamily: 'Microsoft JhengHei', fontSize: 21, fontWeight: 'bold' }}>{this.props.title}</p>
                                        </div>
                                        <Close style={{ marginRight: '25px', cursor: 'pointer' }} onClick={() => this.onClose()} />
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        <div className="top-content">
                            <div className="top-content">
                                <div
                                    id={`layout-${this.props.id}`}
                                    className="bounds"
                                >
                                    {/* 顯示對方鏡頭 */}
                                    {this.state.subscribers.map((sub, i) => (
                                        <div
                                            key={i}
                                            className="OT_root OT_publisher custom-class"
                                            id="remoteUsers"
                                        >
                                            <StreamComponent
                                                user={sub}
                                                streamId={sub.streamManager.stream.streamId}
                                                muted={volumn}
                                            />
                                            <div style={{ position: 'relative', top: '-40px', margin: '-10px 20px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    {volumn ? (
                                                        <div style={buttonStyle}>
                                                            <VolumeOff onClick={() => this.handleSetMutedSound(!volumn)} />
                                                        </div>
                                                    ) : (
                                                        <div style={buttonStyle}>
                                                            <VolumeOn onClick={() => this.handleSetMutedSound(!volumn)} />
                                                        </div>
                                                    )}

                                                    {fullscreen ? (
                                                        <div style={buttonStyle}>
                                                            <FullscreenOff onClick={() => this.toggleFullscreen(!fullscreen)} />
                                                        </div>
                                                    ) : (
                                                        <div style={buttonStyle}>
                                                            <FullscreenOn onClick={() => this.toggleFullscreen(!fullscreen)} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {this.state.subscribers.length === 0 ? <Overlay fullscreen={fullscreen} /> : null}
                        </div>
                    </>
                ) : (
                    <>
                        {(
                            <>
                                <div style={navStyle}>
                                    <Grid container spacing={2} style={{ margin: 0 }}>
                                        <Grid item xs={2} container alignItems="center">
                                            {
                                                !fullscreen ? (
                                                    <Button onClick={() => this.toggleFullscreen(true)} style={{ zIndex: 1000, height: 50, width: 50, fontFamily: 'Microsoft JhengHei', fontSize: 21, fontWeight: 'bold', color: 'white' }}>
                                                        <FullScreen />
                                                    </Button>
                                                ) : null
                                            }
                                        </Grid>
                                        <Grid item xs={10} style={{ marginTop: -18, transform: 'translateX(-39px)' }}>
                                            <Grid item xs={12} style={{ height: 36, color: 'white', fontFamily: 'Microsoft JhengHei', fontSize: 21, fontWeight: 'bold' }}>
                                                {this.props.title}
                                            </Grid>
                                            <Grid item xs={12} style={{ height: 36 }}>
                                                {(this.state.subscribers.length === 0 ? "00:00:00" : <Timer />)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="top-content">
                                    {/* <input type="button" id="buttonApplyFilter" onmouseup="applyFilter()" value="Apply filter"></input> */}

                                    {/* <ToolbarComponent
                                sessionId={mySessionId}
                                user={localUser}
                                showNotification={this.state.messageReceived}
                                camStatusChanged={this.camStatusChanged}
                                micStatusChanged={this.micStatusChanged}
                                screenShare={this.screenShare}
                                stopScreenShare={this.stopScreenShare}
                                toggleFullscreen={this.toggleFullscreen}
                                leaveSession={this.leaveSession}
                                toggleChat={this.toggleChat}
                                applyFilter={this.applyFilter}
                                fullscreen={this.props.fullscreen}
                                mode={this.props.mode}
                                showInlineButton={this.props.showInlineButton}
                                subscribers={this.state.subscribers}
                                sessionStartTime={this.state.sessionStartTime}
                                showSubscriberInfo={
                                    this.props.showSubscriberInfo
                                }
                                speaker={this.state.speaker}
                            /> */}

                                    {/* <DialogExtensionComponent
                                showDialog={this.state.showExtensionDialog}
                                cancelClicked={this.closeDialogExtension}
                            /> */}

                                    <div
                                        id={`layout-${this.props.id}`}
                                        className="bounds"
                                    >
                                        {/* 顯示自己鏡頭 */}
                                        {this.props.isPriorityTalk &&
                                            (!this.state.speaker.connectionId ||
                                                this.state.speaker.connectionId ===
                                                this.state.localUser
                                                    .connectionId) &&
                                            localUser !== undefined &&
                                            localUser.getStreamManager() !==
                                            undefined &&
                                            localUser.getMode() === 'VideoConference' &&
                                            role !== 'SUBSCRIBER' && (
                                                <div
                                                    className="OT_root OT_publisher custom-class"
                                                    id="localUser"
                                                >
                                                    <StreamComponent
                                                        user={localUser}
                                                        handleNickname={
                                                            this.nicknameChanged
                                                        }
                                                    />
                                                </div>
                                            )}
                                        {/* 顯示對方鏡頭 */}
                                        {this.state.subscribers.map((sub, i) => (
                                            // <div key={i} className="OT_root OT_publisher custom-class" id="remoteUsers" style={this.getDisplay()}>
                                            <div
                                                key={i}
                                                className="OT_root OT_publisher custom-class"
                                                id="remoteUsers"
                                            >
                                                <StreamComponent
                                                    user={sub}
                                                    streamId={
                                                        sub.streamManager.stream
                                                            .streamId
                                                    }
                                                />
                                            </div>
                                        ))}
                                        {/* {localUser !== undefined &&
                                    !localUser.isVideoActive() &&
                                    localUser.isAudioActive() && (
                                        <div className="img-wrapper">
                                            <img
                                                src={phone_default}
                                                alt="audio default pic"
                                            />
                                        </div>
                                    )} */}
                                        {/* {localUser !== undefined &&
                                    localUser.getStreamManager() !==
                                        undefined && (
                                        <div
                                            className="OT_root OT_publisher custom-class"
                                            style={chatDisplay}
                                        >
                                            <ChatComponent
                                                user={localUser}
                                                chatDisplay={
                                                    this.state.chatDisplay
                                                }
                                                close={this.toggleChat}
                                                messageReceived={
                                                    this.checkNotification
                                                }
                                            />
                                        </div>
                                    )} */}
                                    </div>
                                </div>
                                {/* {this.props.fullscreen && (
                            <FooterToolbarComponent
                                leaveSession={this.leaveSession}
                                mode={this.props.mode}
                                sessionStartTime={this.state.sessionStartTime}
                            />
                        )} */}
                                {this.state.subscribers.length === 0 ? <Overlay fullscreen={fullscreen} /> : null}
                            </>
                        )}
                        <div>
                            <div style={footerStyle}>
                                <Grid container style={{ height: '100%' }}>
                                    <Grid item xs={2} container alignItems="center">
                                        <Button onClick={() => this.micStatusChanged()} style={{ zIndex: 1000, transform: 'translateX(20px)', border: '1px solid #006E94', borderRadius: '4px', height: '43px', width: '43px' }}>
                                            {localUser && !localUser.isAudioActive() ? (<VoiceOff />) : (<VoiceOn />)}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={10} style={{ transform: 'translateX(-30px)' }} >
                                        <Button onClick={() => this.onClose()} style={{ color: 'white', backgroundColor: (this.state.isLeaveSession ? 'gray' : '#DB4242'), borderRadius: '4px', width: 84, height: 43, fontFamily: 'Microsoft JhengHei', fontSize: 21, fontWeight: 'bold' }}>
                                            掛斷
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }

    /**
     * --------------------------
     * SERVER-SIDE RESPONSIBILITY
     * --------------------------
     * These methods retrieve the mandatory user token from OpenVidu Server.
     * This behaviour MUST BE IN YOUR SERVER-SIDE IN PRODUCTION (by using
     * the API REST, openvidu-java-client or openvidu-node-client):
     *   1) Initialize a session in OpenVidu Server	(POST /api/sessions)
     *   2) Generate a token in OpenVidu Server		(POST /api/tokens)
     *   3) The token must be consumed in Session.connect() method
     */

    getToken() {
        return this.createSession(this.state.mySessionId).then((sessionId) =>
            this.createToken(sessionId)
        );
    }

    createSession(sessionId) {
        return new Promise((resolve, reject) => {
            const data = JSON.stringify({
                customSessionId: sessionId,
                // , recordingMode : 'ALWAYS'
                // , defaultRecordingProperties: {
                //     outputMode: "INDIVIDUAL",
                //     shmSize: 1048576,
                // }
                // , defaultOutputMode : "INDIVIDUAL"
            });
            axios
                .post(
                    `${this.OPENVIDU_SERVER_URL}/openvidu/api/sessions`,
                    data,
                    {
                        headers: {
                            Authorization: `Basic ${btoa(
                                `OPENVIDUAPP:${this.OPENVIDU_SERVER_SECRET}`
                            )}`,
                            'Content-Type': 'application/json',
                        },
                    }
                )
                .then((response) => {
                    console.log('CREATE SESION', response);
                    resolve(response.data.id);
                })
                .catch((response) => {
                    const error = { ...response };
                    if (error.response && error.response.status === 409) {
                        resolve(sessionId);
                    } else {
                        console.log(error);
                        console.warn(
                            `No connection to OpenVidu Server. This may be a certificate error at ${this.OPENVIDU_SERVER_URL}`
                        );
                        if (
                            window.confirm(
                                `No connection to OpenVidu Server. This may be a certificate error at "${this.OPENVIDU_SERVER_URL}"\n\nClick OK to navigate and accept it. ` +
                                `If no certificate warning is shown, then check that your OpenVidu Server is up and running at "${this.OPENVIDU_SERVER_URL}"`
                            )
                        ) {
                            window.location.assign(
                                `${this.OPENVIDU_SERVER_URL}/accept-certificate`
                            );
                        }
                    }
                });
        });
    }

    createToken(sessionId) {
        return new Promise((resolve, reject) => {
            const data = JSON.stringify({
                role: this.state.role, // MODERATOR SUBSCRIBER PUBLISHER
                data: JSON.stringify(this.props.userPriority),
                // , location: 'DaYun 98 rd Kaohsiung, Taiwan'
            });
            axios
                .post(
                    `${this.OPENVIDU_SERVER_URL}/openvidu/api/sessions/${sessionId}/connection`,
                    data,
                    {
                        headers: {
                            Authorization: `Basic ${btoa(
                                `OPENVIDUAPP:${this.OPENVIDU_SERVER_SECRET}`
                            )}`,
                            'Content-Type': 'application/json',
                        },
                    }
                )
                .then((response) => {
                    console.log('TOKEN', response);
                    resolve(response.data.token);
                })
                .catch((error) => reject(error));
        });
    }
}
export default VideoRoomComponent;


const overlayStyle = {
    position: 'absolute',
    top: 71,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1001, /* 确保遮罩在目标元素之上 */
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const overlayStyleFullScreen = {
    position: 'absolute',
    top: 79,
    left: 0,
    width: '100%',
    height: 'calc(100% - 159px)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1001, /* 确保遮罩在目标元素之上 */
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const contentStyle = {
    //backgroundColor: '#fff', /* 内容区背景色 */
    color: 'white',
    padding: 20,
    zIndex: 1002, /* 确保内容在遮罩之上 */
}

const Overlay = (props) => {
    const { fullscreen } = props;
    return (
        <div style={fullscreen ? overlayStyleFullScreen : overlayStyle}>
            {/* 这里可以放置要遮罩的内容 */}
            <div style={contentStyle}>
                <p>撥號中...</p>
            </div>
        </div>
    );
};

const Timer = () => {
    let seconds = 0;
    let intervalId;
    const [result, setResult] = useState('00:00:00');

    const startTimer = () => {
        if (!intervalId) {
            intervalId = setInterval(updateTimer, 1000); // 每秒更新一次计时器
        }
    }

    const pauseTimer = () => {
        clearInterval(intervalId); // 暂停计时器
        intervalId = null;
    }

    const resetTimer = () => {
        seconds = 0;
        updateTimerDisplay();
        pauseTimer(); // 如果计时器正在运行，则先暂停
    }

    const updateTimer = () => {
        seconds++;
        updateTimerDisplay();
    }

    const updateTimerDisplay = () => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        setResult(`${padZero(hours)}:${padZero(minutes)}:${padZero(remainingSeconds)}`);
    }

    const padZero = (number) => {
        return number.toString().padStart(2, '0');
    }

    useEffect(() => {
        startTimer();
    }, []);

    return (
        <div>{result}</div>
    )
}