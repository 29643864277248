
import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios';
import { AppConfig } from '../src/static/config/config';

const { API_URL } = AppConfig;

export const createConnection = (data, token) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${API_URL}/amr-openvidu/device/connection/create`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            )
            .then((response) => {
                console.log('TOKEN', response);
                resolve(response.data.token);
            })
            .catch((error) => {
                console.log(error);
                reject(error)
            });
    });
};

export const getConnections = (sessionId, token) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${API_URL}/amr-openvidu/device/session/${sessionId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            )
            .then((response) => {
                console.log('response', response);
                resolve(response);
            })
            .catch((error) => {
                console.log(error);
                reject(error)
            });
    });
};

class ApiRoot {
    // const api; // AxiosInstance;

    // const config; // AxiosRequestConfig;

    constructor(config) {
        this.config = config;
        this.api = axios.create(config);
        this.api.interceptors.request.use((Request) => {
            if (Request.url?.indexOf('logout') === -1) {
                setIdleTime(0);
            }
            const dt = +new Date() + 1800000;
            sessionStorage.setItem('timeout', dt.toString());
            // spinnerCtrl(true);
            // eslint-disable-next-line no-param-reassign
            Request.headers.Authorization = this.getAuthorization();
            return Request;
        });

        this.api.interceptors.response.use(
            (Response) => {
                // spinnerCtrl(false);
                return Promise.resolve(Response);
            },
            async (error) => {
                // spinnerCtrl(false);
                let response = {
                    data: { errorMsg: 'API_ERROR.SYSTEM_ERROR' },
                    status: 500,
                    statusText: '',
                    headers: undefined,
                    config: {},
                };
                if (isAxiosError(error)) {
                    response = { ...response, ...error };
                }
                switch (response.status) {
                    case 400:
                        console.log('400');
                        break;
                    case 401:
                        const refreshTokenRes = await this.refreshToken();
                        const accessWicentral = refreshTokenRes['access-wicentral'];
                        sessionStorage.setItem('tokenWicentral', accessWicentral);
                        if (error instanceof AxiosError && error.config) {
                            error.config.headers.Authorization = `Bearer ${accessWicentral}`;
                            return this.api.request(error.config);
                        }
                        break;
                    case 403:
                        localStorage.removeItem('token');
                        window.location.href = PATH.LOGIN;
                        break;
                    case 404:
                        console.log('404');
                        break;
                    case 412:
                        break;
                    case 500:
                        break;
                    default:
                        break;
                }
                return Promise.reject(response);
            }
        );
    }

    getApi() {
        return this.api;
    }

    getAuthorization() {
        return localStorage.getItem('token');
    }

    getConfig() {
        return this.config;
    }

    async refreshToken() {
        const refresh = sessionStorage.getItem('refresh');
        const auth = new ApiAuth({
            ...AUTH_CONFIG,
        }).getApi();

        const { data } = new Promise((resolve, reject) => {
            axios
                .post(
                    `${API_URL}/wif-wic-aspect/login/refreshToken/`,
                    { 'refresh': refresh },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*'
                        },
                    }
                )
                .then((response) => {
                    console.log('TOKEN', response);
                    resolve(response.data.token);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error)
                });
        });

        const accessWicentral = data['access-wicentral'];
        sessionStorage.setItem('tokenWicentral', accessWicentral);
        return data;
    }
}

class ApiAuth extends ApiRoot {
    getAuthorization() {
        return `Bearer ${localStorage.getItem('token')}`;
    }
}