import 'zone.js'; // for angular subapp
import { initGlobalState, registerMicroApps, runAfterFirstMounted, setDefaultMountApp, start } from 'qiankun' //'../../es';
import './index.less';
/* qiankun教學 
1. https://www.gushiciku.cn/pl/puLt/zh-tw
2. https://segmentfault.com/a/1190000024551391
3. https://developer.aliyun.com/article/919870
*/

/**
 * 主应用 **可以使用任意技术栈**
 * 以下分别是 React 和 Vue 的示例，可切换尝试
 */
import render from './render/ReactRender';
// import render from './render/VueRender';

/**
 * Step1 初始化应用（可选）
 */
render({ loading: true });

const loader = (loading) => render({ loading });

/**
 * Step2 注册子应用
 * 參數傳遞方法
 * https://blog.csdn.net/Lyrelion/article/details/123735057
 */

registerMicroApps(
  [
    {
      name: 'amr',
      entry: 'https://subapp.cloud.wifundity.com',
      container: '#subapp-viewport',
      loader,
      activeRule: '/amr',
    },
    {
      name: 'script',
      entry: 'https://order.cloud.wifundity.com',
      container: '#subapp-viewport',
      loader,
      activeRule: '/wifundity',
    },
  ],
  {
    beforeLoad: [
      (app) => {
        console.log('[LifeCycle] before load %c%s', 'color: green;', app.name);
        rediret(app.name);
      },
    ],
    beforeMount: [
      (app) => {
        console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name);
      },
    ],
    afterUnmount: [
      (app) => {
        console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name);
      },
    ],
  },
);

const rediret = (value) => {
  switch(value) {
    // case 'order':
    //   sessionStorage.setItem('currentPage', 'Order');
    //   break;
    // // Script
    case 'script':
      // sessionStorage.setItem('currentPage', 'Script');
      if (window.location.pathname.includes('script-management')) { // 'wifundity/script-management'
        sessionStorage.setItem('currentPage', 'Script');
      } else { // wifundity/script-management
        sessionStorage.setItem('currentPage', 'Order');
      }
      break;
    default:
      var path = window.location.hash.replace('#/','');
      sessionStorage.setItem('currentPage', path);
      break;
  }
}

const { onGlobalStateChange, setGlobalState } = initGlobalState({
  user: 'qiankun',
});

onGlobalStateChange((value, prev) => console.log('[onGlobalStateChange - master]:', value, prev));

setGlobalState({
  ignore: 'master',
  user: {
    name: 'master',
  },
});

/**
 * Step3 设置默认进入的子应用
 */
// setDefaultMountApp('/react16');
setDefaultMountApp('/amr');

/**
 * Step4 启动应用
 */
start({ singular: false });

runAfterFirstMounted(() => {
  console.log('[MainApp] first app mounted');
});

// function push(subapp) { history.pushState(null, subapp, subapp) }

// function initPortal(){
//   //主应用跳转
//   document.querySelector('.app1').onclick = () => {
//     document.querySelector('.subapp-viewport2').style.visibility = 'hidden'
//     document.querySelector('.subapp-viewport3').style.visibility = 'hidden'
//     push('/app1')
//   }
//   document.querySelector('.app2').onclick = () => {
//     document.querySelector('.mainapp-sidemenu').style.visibility = 'hidden'
//     push('/app2')
//   }


//   if(location.pathname !== '/'){
//     document.querySelector('.mainapp-sidemenu').style.visibility = 'hidden'
//   }else{
//     document.querySelector('.mainapp-sidemenu').style.visibility = 'visible'
//   }
//   if(location.pathname.indexOf('login') > -1){
//     document.querySelector('.mainapp-header').style.display = 'block'
//   }else{
//     document.querySelector('.mainapp-header').style.display = 'none'
//   }

//   //监听浏览器前进回退
//   window.addEventListener('popstate', () => { 
//     if(location.pathname === '/'){
//       document.querySelector('.mainapp-sidemenu').style.visibility = 'visible'
//     }
//     if(location.pathname.indexOf('login') > -1){
//       document.querySelector('.mainapp-header').style.display = 'block'
//     }else{
//       document.querySelector('.mainapp-header').style.display = 'none'
//     }
//   }, false)
// }

// initPortal()
