/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import './StreamComponent.css';

import OvVideoComponent from './OvVideo';

export default class StreamComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nickname: this.props.user?.getNickname(),
            showForm: false,
            // mutedSound: this.props.muted,
            isFormValid: true,
        };
    }
    render() {
        return (
            <div className="OT_widget-container" style={{ height: '100%' }}>
                {this.props.user !== undefined &&
                    this.props.user?.getStreamManager() !== undefined ? (
                    <div className="streamComponent">
                        <OvVideoComponent
                            user={this.props.user}
                            mutedSound={this.props.muted}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}
