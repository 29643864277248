import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { makeStyles, Button, Grid } from '@material-ui/core';
import color from '../../color/theme';
// import useAudio from '../../hooks/useAudio';

// const onlyMessageModalStyle = makeStyles(() => ({
//     nav: {
//         textAlign: 'center',
//         height: '64px',
//         lineHeight: '64px',
//         fontSize: '21px',
//         color: '#fff',
//         backgroundColor: '#006E94',
//         borderTopRightRadius: '10px',
//         borderTopLeftRadius: '10px',
//         boxShadow: 'rgba(0, 0, 0, 0.35) 0px 35px 15px',
//     },
//     title: {
//         textAlign: 'center',
//         height: '64px',
//         lineHeight: '64px',
//         fontSize: '21px',
//         fontWeight: 'bold',
//         color: '#fff',
//         backgroundColor: color.blue1,
//         borderTopLeftRadius: '10px',
//         borderTopRightRadius: '10px',
//     },
//     footer: {
//         textAlign: 'center',
//         height: '80px',
//         lineHeight: '80px',
//         border: '1px solid #EEEEEE',
//         borderBottomLeftRadius: '10px',
//         borderBottomRightRadius: '10px',
//     },
// }));

const IncomingCallPopover = (props) => {
    const {
        body,
        isOpen,
        deviceId,
        roomNameM,
        onClose,
        onOK,
        index,
    } = props;
    // const [playing, toggle] = useAudio('https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3');

    // const modalStyles = onlyMessageModalStyle();
    const modalStyle = {
        position: 'fixed',
        top: `calc(95% - 100px - ${index * 230}px)`, // 'calc(95% - 100px)',
        left: 'calc(95% - 200px)',
        transform: 'translate(-50%, -50%)',
        zIndex: 10000,
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 35px 15px',
    };

    const centeredTextStyle = {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap', /* 允许文本内容自动换行 */
        width: '100%', /* 设置容器的宽度，根据需要调整 */
        backgroundColor: '#F4FBFC',
        color: '#555555',
        fontFamily: 'Microsoft JhengHei',
        fontSize: 16,
        fontWeight: 'bold'
    };
    const [isDisabled, setIsDisabled] = useState(false);

    const handleOK = () => {
        if (onOK) onOK({ deviceId, body, roomNameM });
    };

    const handleClose = () => {
        setIsDisabled(true);
        // Yuru 要求掛斷延遲1秒
        setTimeout(() => {
            if (onClose) onClose(deviceId);
        }, 1000);
    };

    if (!isOpen) return null;

    useEffect(() => {
        // if (isOpen) toggle();
        setTimeout(() => {
            onClose(deviceId);
        }, 1000 * 30);
    }, [isOpen]);

    const navStyle = {
        textAlign: 'center',
        lineHeight: '64px',
        fontSize: '21px',
        color: '#fff',
        backgroundColor: '#006E94',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 35px 15px',
        height: 60,
    };

    const bodyStyle = {
        backgroundColor: '#F4FBFC',
        height: 100,
        textAlign: 'center',
    };

    const footerStyle = {
        textAlign: 'center',
        height: 50,
        // lineHeight: '80px',
        border: '1px solid #EEEEEE',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        width: '100%',
        backgroundColor: '#F4FBFC'
    };

    const getBtnStyle = () => {
        if (isDisabled) {
            return { backgroundColor: 'gray', color: 'white', borderRadius: '4px', width: 84, height: 33, fontFamily: 'Microsoft JhengHei', fontSize: 16, fontWeight: 'bold'};   
        }
        return { backgroundColor: '#DB4242', color: 'white', borderRadius: '4px', width: 84, height: 33, fontFamily: 'Microsoft JhengHei', fontSize: 16, fontWeight: 'bold'};
    }
    return createPortal(
        <div style={modalStyle}>
            <div style={{ width: 320, height: 210 }}>
                {/* <div className={modalStyles.nav} style={{ height: 60, fontFamily: 'Microsoft JhengHei', fontSize: 21, fontWeight: 'bold', color: 'white' }}>
                    語音通話
                </div>
                <div>
                    <div style={{...centeredTextStyle, height: 70}}>{body}</div>
                    <div style={{...centeredTextStyle, height: 30}}>正在撥號給您</div>
                </div>
                <div style={{ height: 50 }}>
                    <div className={modalStyles.footer} style={{ width: '100%', backgroundColor: '#F4FBFC' }}>
                        <Button onClick={handleClose} style={{ backgroundColor: '#DB4242', color: 'white', borderRadius: '4px', width: 84, height: 43, fontFamily: 'Microsoft JhengHei', fontSize: 16, fontWeight: 'bold'}}>
                            掛斷
                        </Button>
                        <Button onClick={handleOK} style={{ marginLeft: 10, color: 'white', backgroundColor: '#006E94', borderRadius: '4px', width: 84, height: 43, fontFamily: 'Microsoft JhengHei', fontSize: 16, fontWeight: 'bold'}}>
                            接聽
                        </Button>
                    </div>
                </div> */}
                <div style={navStyle}>
                    <Grid container
                      spacing={2} 
                      style={{ margin: 0 }} 
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                        語音通話
                    </Grid>
                </div>
                <div style={bodyStyle}>
                    <Grid container
                      spacing={2} 
                      style={{ margin: 0, width: '100%' }} 
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                        <Grid item xs={12} style={{marginTop: 10}}>
                            <div style={centeredTextStyle}>
                                {body}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={centeredTextStyle}>
                                正在撥號給您
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div style={footerStyle}>
                    <Grid container
                     spacing={2} 
                     style={{ margin: 0 }} 
                     direction="column"
                     alignItems="center"
                     justifyContent="center"
                    >
                        <Grid item xs={12}>
                            <Button disabled={isDisabled} onClick={handleClose} style={getBtnStyle()}>
                                掛斷
                            </Button>
                            <Button onClick={handleOK} style={{ marginLeft: 10, color: 'white', backgroundColor: '#006E94', borderRadius: '4px', width: 84, height: 33, fontFamily: 'Microsoft JhengHei', fontSize: 16, fontWeight: 'bold'}}>
                                接聽
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default IncomingCallPopover;