import axios from 'axios';
import { AppConfig } from '../src/static/config/config';

const { WIFUNDITY_NOTIFICATION } = AppConfig;

const aspectRequest = axios.create({
    baseURL: `${WIFUNDITY_NOTIFICATION}`,
  });
  
  const getToken = () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('tokenWicentral')}`,
      },
    };
    return config;
  };
  
  export const apiUpdateByDeviceId = (data) => aspectRequest.put('/device/handle', data, getToken());